main {
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 10px;
  padding: 10px;
  display: flex;
}

article {
  line-height: 1.5;
}

.decorated-anchor {
  color: var(--anchor-color);
  font-weight: 600;
}

.decorated-anchor:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=index.7710d752.css.map */
