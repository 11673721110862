main {
  margin: 10px;
  padding: 10px;

  max-width: 1000px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

article {
  line-height: 1.5;
}

.decorated-anchor {
  font-weight: 600;
  color: var(--anchor-color);
}

.decorated-anchor:hover {
  text-decoration: underline;
}
